import React, { useState, useEffect } from "react";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import productionTestingApi from "../../../api/productTestingApi";
import cameraModelApi from "../../../api/cameraModelApi";


function LinkCameraModel(props) {
  const { t } = props;
  let test = props.tests.find(test => test.id === props.testId);
  const { handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
        name: test.name || '',
        version: test.version || ''
    }
  })

  const [cameraModels, setCameraModels] = useState([])
  const [cameraModelsLinked, setCameraModelsLinked] = useState(test.cameraModels)

  useEffect(() => {
    cameraModelApi.getCameraModels()
    .then(res => {
        let cameraModelsArray = [];
        res.data.forEach(cameraModels => {
          if (!cameraModelsLinked.includes(cameraModels.commonName)) {
            cameraModelsArray.push(cameraModels.commonName);
          }
        });

        // Ensure unique values
        cameraModelsArray = [...new Set(cameraModelsArray)];

        setCameraModels(cameraModelsArray);
    })
    .catch(err => {
        console.log(err)
    });
  }, []);

  function addCameraModel() {
    let dropDown = document.getElementById("cameraModels")
    let index = cameraModels.indexOf(dropDown.value)
    cameraModels.splice(index, 1)
    setCameraModels([...cameraModels])
    cameraModelsLinked.push(dropDown.value)
    setCameraModelsLinked(cameraModelsLinked)
  }

  function deleteCameraModel(e) {
    cameraModels.push(e.target.id)
    setCameraModels([...cameraModels])
    let index = cameraModelsLinked.indexOf(e.target.id)
    cameraModelsLinked.splice(index, 1)
    setCameraModelsLinked(cameraModelsLinked)
  }

  function onSubmit() {
    const testData = {
      name : test.name,
      version: test.version,
      regex: test.regex,
      cameraModels: cameraModelsLinked
    };
    productionTestingApi.editProductionTesting(test.id, testData)
      .then( res => {
        props.onClose();
      })
      .catch(err => {
        throw err
    });
  }

    function makeTable() {
        return cameraModelsLinked.map((model, key) =>
        <tr key={key}>
          <td className="center-align">{model}</td>
          <td><ButtonFactory name={model} icon='delete' onClick={deleteCameraModel}/></td>
        </tr>)
    }

    return(
      <div id="linkCameraModel" style={{  maxHeight: "95%", position:"fixed", width:"900px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"-95px", marginLeft:" calc(45% - 730px)", zIndex:"100",overflowY:"scroll" }} className="container center-align">
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.testQC_link')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
       <Col m={4} s={12}>
          <label className="active" htmlFor="name" style={{ width: "300px", display: "inline-block" }} >{t('input.name')}</label>
          <input type="text" name="name" readOnly value={test.name} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.name && <p className="red-text" >{errors.name.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="version" style={{ width: "300px", display: "inline-block" }} >{t('setting.version')}</label>
          <input type="text" name="version" readOnly value={test.version} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.version && <p className="red-text" >{errors.version.message}</p>}
        </Col>
        <table style={{ marginTop: "25px" }} >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <td className="center-align">{t('dashboard_main.camera_model')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {makeTable()}
            <tr>
                <td>
                    <select id="cameraModels" name="cameraModels" className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
                        {cameraModels.map((cameraModel, index) => (
                            <option key={index} value={cameraModel}>{cameraModel}</option>
                        ))}
                    </select>
                    {errors.cameraModels && <p className="red-text" >{errors.cameraModels.message}</p>}
                </td>
                <td>
                    <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={addCameraModel}/>
                </td>
            </tr>

          </tbody>
        </table>
        <div>

        </div>
        <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
        </div>
       </form>
      </div>
    );
}

LinkCameraModel.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(LinkCameraModel);
